const getParams = () => {
    const result = {}

    const params = document.location.hash.replace('#', '')

    if (params) {
        new URLSearchParams(params).forEach((value, key) => {
            result[key] = value
        })
    }
    return result
}

const setDocumentLanguage = languageToSet => {
    document.documentElement.lang = languageToSet
    document.location.hash = `lang=${languageToSet}`
}

const setInitialLanguage = (langFromUrl) => {
    if (langFromUrl) return setDocumentLanguage(langFromUrl);
    const [browserLang] = navigator.language.split('-')
    setDocumentLanguage(browserLang)
}

const getOppositeLang = () => {
    const actualLang = document.documentElement.lang;
    if (actualLang === 'en') return 'pl'
    return 'en'
}

document.addEventListener('DOMContentLoaded', () => {
    const params = getParams();
    const bodyElement = document.querySelector('body');


    // NAV STICKY
    const navElement = document.querySelector('.nav')

    const onNavStick = ([e]) => {
        navElement.classList.toggle('sticky', !e.isIntersecting)
    }

    const observer = new IntersectionObserver(onNavStick, {
        rootMargin: '-1px 0px 0px 0px',
        threshold: [1],
    });

    observer.observe(navElement);

    // LANG BUTTONS
    const langButtonsElement = document.querySelector('.toggle-language-button')

    setInitialLanguage(params.lang)

    const onLanguageButtonClick = () => setDocumentLanguage(getOppositeLang())

    langButtonsElement.addEventListener('click', onLanguageButtonClick)

    // FONTS
    document.fonts.onloadingdone = function () {
        bodyElement.classList.remove('fonts-loading')
    };

    setTimeout(() => {
        bodyElement.classList.remove('fonts-loading')
    }, 5000)

    // PRINT
    if(!!params.printable_version)  {
        bodyElement.classList.add('printable')
    }
})